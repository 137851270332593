import React from "react";
import { Navbar, Nav, Dropdown, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { deleteToken } from "../services/token";
import { getUserMe } from "../services/api";
import { useDispatch } from "react-redux";
import { addUser } from "../user";
import { addImageFallback } from "../services/helpers";

export default function Header() {
  const [user, setUser] = React.useState({});
  const dispatch = useDispatch();

  const getUsersMe = async () => {
    let data = await getUserMe();
    dispatch(addUser(data));
    if (data) {
      setUser(data);
    } else {
      deleteToken();
    }
  };

  React.useEffect(() => {
    getUsersMe();
  }, [dispatch]);

  return (
    <>
      <header className="light-bb">
        <Navbar expand="lg">
          <Link className="navbar-brand" to="/">
            Home
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-nav">
              <Link to="/logs">
                Logs
              </Link>
              <Link to="/users">
                Users
              </Link>
              <Link to="/drills">
                Practices
              </Link>
              <Link to="/programs">
                Programs
              </Link>
              <Link to="/assessments/templates">
                Assessment templates
              </Link>
              <Link to="/wordings">
                Wordings
              </Link>
              <Link to="/app-settings">
                App settings
              </Link>
            </Nav>
            <Nav className="navbar-nav ml-auto">
              <Dropdown className="header-custom-icon"></Dropdown>
              <Dropdown className="header-img-icon">
                <Dropdown.Toggle variant="default">
                  <img
                    src={"" + process.env.REACT_APP_AVATAR_URL + user.avatar}
                    onError={addImageFallback}
                    alt="avatar"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="dropdown-header d-flex flex-column align-items-center">
                    <div className="info text-center">
                      <p className="name font-weight-bold mb-0">
                        {user.full_name}
                      </p>
                      <p className="email text-muted mb-3">{user.email}</p>
                    </div>
                  </div>
                  <div className="dropdown-body">
                    <ul className="profile-nav">
                      <li className="nav-item">
                        <Link to="/profile" className="nav-link">
                          <i className="icon ion-md-person"></i>
                          <span>Profile</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="/login"
                          className="nav-link red"
                          onClick={deleteToken}
                        >
                          <i className="icon ion-md-power"></i>
                          <span>Log Out</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </>
  );
}
