import { GET, POST, PUT, DELETE } from "./api";

const createProgramAssessment = (program_id, program_assessment) => {
  return POST("/programs/" + program_id + "/assessments/", program_assessment);
};

const deleteProgramAssessment = (program_id, assessment_id) => {
  return DELETE("/programs/" + program_id + "/assessments/" + assessment_id);
};

export { createProgramAssessment, deleteProgramAssessment };
