import React from 'react';
import {Login} from '../../services/api'
import {saveToken} from "../../services/token";
import {useAlert} from "react-alert";
import {showError} from "../../utils";
export default function LoginPage() {
    const refUsername = React.useRef(null)
    const refPassword = React.useRef(null)
    const alert = useAlert()

    const handleSubmit = async (e) => {
        e.preventDefault()
        const authenticate = {
            'username': refUsername.current.value,
            'password': refPassword.current.value
        }


        let response = await Login(authenticate)

        if (response.status === 200) {
            saveToken(response.data.access_token)
            window.location.pathname = '/'
        } else {
            showError(alert, response)
        }

        return false
    }

    React.useEffect(()=>{
        refUsername.current.focus()
    }, [])
    return (
        <>
            <div className="vh-100 d-flex justify-content-center">
                <div className="form-access my-auto">
                    <form onSubmit={handleSubmit} method="POST">
                        <span>Sign In</span>
                        <div className="form-group">
                            <input
                                ref={refUsername}
                                type="email"
                                name="username"
                                className="form-control"
                                placeholder="Email Address"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <input
                                ref={refPassword}
                                type="password"
                                name="password"
                                className="form-control"
                                placeholder="Password"
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                            Sign In
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}
