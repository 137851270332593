import React from "react";
import { getLogs, deleteLog, getUsers, getDrills } from "../../services/api";
import { useAlert } from "react-alert";
import withToken from "../../hocs/withToken";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import { parseDateISOString } from "../../services/helpers";
import ConfirmDialog from "../../components/Confirm";
import Button from "@mui/material/Button";

const LogList = () => {
  const history = useHistory();
  const [logs, setLogs] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [drills, setDrills] = React.useState([]);
  const [confirmState, setConfirmState] = React.useState({ open: false });

  const alert = useAlert();
  const editHandler = (id) => {
    window.location.href = "/logs/" + id;
  };

  const deleteHandler = (id) => {
    setTimeout(() => {
      deleteLog(id).then(() => {
        refreshData();
      });
    }, 500);
  };

  const deleteConfirm = (id) => {
    setConfirmState({
      open: true,
      id: id,
      text: "Do you really want to delete this log?"
    });
  };

  React.useEffect(() => {
    getDrills(0, 1000)
      .then(setDrills)
      .catch((error) => alert.show(error));
    getUsers(0, 1000)
      .then(setUsers)
      .catch((error) => alert.show(error));

    refreshData();
  }, []);

  const refreshData = (_) => {
    getLogs(0, 1000)
      .then(setLogs)
      .catch((error) => alert.show(error));
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "user_id",
      headerName: "User",
      flex: 1,
      valueFormatter: (params) => users[params?.value]?.full_name,
    },
    {
      field: "drill_id",
      headerName: "Drill",
      flex: 1,
      valueFormatter: (params) => drills[params?.value]?.name,
    },
    {
      field: "quality",
      headerName: "Quality",
      flex: 1,
    },
    {
      field: "resistance",
      headerName: "Resistance",
      flex: 1,
    },
    {
      field: "finished",
      headerName: "Finished",
      flex: 1,
      valueFormatter: (params) =>
        parseDateISOString(params?.value).toLocaleDateString(),
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => editHandler(params.id)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => deleteConfirm(params.id)}
        />,
      ],
    },
  ]; 

  return (
    <>
      <h3 className="d-flex justify-content-between">
        <div>List of logs</div>
        <Button
          variant="contained"
          onClick={() => {
            editHandler(0);
          }}
        >
          Add log
        </Button>
      </h3>
      <DataGrid
        rows={logs}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        autoHeight
      />
      <ConfirmDialog
        state={confirmState}
        setConfirmState={setConfirmState}
        confirmCallback={deleteHandler}
      ></ConfirmDialog>
    </>
  );
};
export default withToken(LogList);
