import React from "react";
import ReactDOM from "react-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import { store } from "./store";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./components/Layout";
import Logs from "./pages/logs";
import LogsEdit from "./pages/logs/edit";
import Users from "./pages/users";
import UsersNew from "./pages/users/new";
import UsersEdit from "./pages/users/edit";
import Drills from "./pages/drills";
import DrillsNew from "./pages/drills/new";
import DrillsEdit from "./pages/drills/edit";
import Programs from "./pages/programs";
import ProgramsEdit from "./pages/programs/edit";
import Profile from "./pages/profile";
import Start from "./pages/start";
import Login from "./pages/auth/login";
import ProgramAssessments from "./pages/program-assessments/index";
import ProgramAssessmentDetails from "./pages/program-assessments/details";
import ProgramAssessmentAnswers from "./pages/program-assessments/answers";
import AssessmentTemplates from "./pages/assessment-templates";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/ionicons.min.css";
import "./assets/scss/style.scss";
import Wordings from "./pages/wordings";
import appSettings from "./pages/app-settings";
const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "100px",
  type: "error",
  transition: transitions.FADE,
};
const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};
ReactDOM.render(
  <React.StrictMode>
    <AlertProvider template={AlertTemplate} {...options}>
      <Provider store={store}>
        <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
          <Route component={ScrollToTop} />
          <Layout>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/logs" component={Logs} exact />
              <Route path="/logs/:id" component={LogsEdit} />
              <Route path="/users" component={Users} exact />
              <Route path="/users-new" component={UsersNew} />
              <Route path="/users/:id" component={UsersEdit} />
              <Route path="/drills" component={Drills} exact />
              <Route path="/drills-new" component={DrillsNew} />
              <Route path="/drills/:id" component={DrillsEdit} />
              <Route exact path="/programs" component={Programs} />
              <Route exact path="/programs/:id" component={ProgramsEdit} />
              <Route
                exact
                path="/programs/:id/assessments"
                component={ProgramAssessments}
              />
              <Route
                exact
                path="/programs/:programId/assessments/:id"
                component={ProgramAssessmentDetails}
              />
              <Route
                exact
                path="/programs/:programId/assessments/:id/answers"
                component={ProgramAssessmentAnswers}
              />
              <Route
                exact
                path="/assessments/templates"
                component={AssessmentTemplates}
              />
              <Route
                exact
                path="/assessments/templates/:id"
                component={ProgramAssessmentDetails}
              />
              <Route path="/profile" component={Profile} />
              <Route path="/wordings" component={Wordings} />
              <Route path="/app-settings" component={appSettings} />
              <Route path="/" component={Start} />
            </Switch>
          </Layout>
        </BrowserRouter>
      </Provider>
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
