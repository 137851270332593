import { useEffect, useState, Fragment } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { getAssessmentTemplates } from "../../services/assessments-api";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { copyTemplateToProgram } from "../../services/prorgrams-api";
import PreviewIcon from "@mui/icons-material/Preview";
import IconButton from "@mui/material/IconButton";

import "../../assets/scss/components/assessment-template-modal.scss"

export default function AssessmentTemplatesModal({
  open,
  setOpen,
  addProgramAssessmentToTable,
  programId
}) {
  const handleClose = () => setOpen(false);
  const [assessmentTemplates, setAssessmentTemplates] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showQuestionsTemplateId, setShowQuestionsTemplateId] = useState(0);

  const handleAdd = () => {
    copyTemplateToProgram(programId, selectedIndex).then((assessment) => {
      addProgramAssessmentToTable(assessment);
      setOpen(false);
    });
  };

  const handleListItemClick = (index) => {
    setShowQuestionsTemplateId(0);
    if (selectedIndex === index) {
      setSelectedIndex(-1);
    } else {
      setSelectedIndex(index);
    }
  };

  useEffect(() => {
    getAssessmentTemplates().then((templates) => {
      setAssessmentTemplates(templates);
    });
  }, []);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} width="400px">
        <DialogTitle>Assessment templates</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select assessment template
          </DialogContentText>

          <Box
            sx={{ width: "100%", maxWidth: 460, bgcolor: "background.paper" }}
          >
            <List component="nav" aria-label="secondary mailbox folder">
              {assessmentTemplates?.map((template) => {
                return (
                  <>
                    <ListItem
                      secondaryAction={
                        <IconButton onClick={() => {
                          setShowQuestionsTemplateId(showQuestionsTemplateId == template.id ? 0 : template.id)}
                          }>
                          <PreviewIcon />
                        </IconButton>
                      }
                    >
                      <ListItemButton
                        className="assessment-template-modal-item"
                        selected={selectedIndex === template.id}
                        onClick={(event) => handleListItemClick(template.id)}
                      >
                        <ListItemAvatar hidden={selectedIndex !== template.id}>
                          <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
                        </ListItemAvatar>
                        <ListItemText
                          primary={<Fragment>{template.name}</Fragment>}
                          secondary={
                            <Fragment>
                              Questions count: {template.questions.length}
                              {showQuestionsTemplateId == template.id && template.questions.map((question, index) => {
                                return (
                                    <div>{index + 1}. {question.name}</div>
                                );
                              })}
                            </Fragment>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  </>
                );
              })}
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAdd} disabled={selectedIndex <= 0}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
