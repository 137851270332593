import withToken from "../../hocs/withToken";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { getAssessmentQuestions } from "../../services/assessment-questions-api";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Button } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";

import "../../assets/scss/components/assessment-details.scss";

const ProgramAssessmentAnswers = () => {
  const [assessmentQuestions, setassessmentQuestions] = useState([]);
  const { id, programId } = useParams();

  useEffect(() => {
    getAssessmentQuestions(id).then(setassessmentQuestions);
  }, []);

  const renderQuestion = (question) => {
    switch (question.type_id) {
      case 1:
        return question.answers.length > 0 ? question.answers.map((answer) => {
          return (
            <>
              <TextField
                className="assessment-question__content-text"
                variant="filled"
                multiline
                placeholder="Your answer"
                disabled
                value={answer.value}
              />
            </>
          );
        }) : <div className="assessment-question__content-text">No answers in this question</div>;
      case 2:
        const chartData = [];
        for (let i = 1; i <= 10; i++) {
          const answers_count = question.answers.filter((el) => {
            return el.value === "" + i;
          }).length;

          chartData.push({ answer: i, count: answers_count });
        }

        return (
          <>
            <div className="assessment-question__content-slider">
              <>
                <div className="assessment-question__content-slider__desktop">
                  <div className="label">{question.options[0].text}</div>
                  <ResponsiveContainer width="100%" height={140}>
                    <BarChart data={chartData}>
                      <XAxis
                        dataKey="answer"
                        axisLine={false}
                        tickLine={false}
                      />
                      <Bar
                        dataKey="count"
                        barSize={30}
                        radius={4}
                        minPointSize={20}
                      >
                        <LabelList
                          dataKey="count"
                          position="insideBottom"
                          fill="white"
                          fontSize={12}
                          fontWeight={500}
                        />
                        {/* {data.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={colors[index]} />
                        ))} */}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                  <div className="label">{question.options[1].text}</div>
                </div>
              </>
            </div>
          </>
        );
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <div>
          <h4>Assessment answers</h4>
        </div>
        <div className="d-flex justify-content-end gap-1">
          <Button
            variant="text"
            onClick={() =>
              (window.location.href = `/programs/${programId}/assessments`)
            }
          >
            Back to list
          </Button>
        </div>
      </div>
      <div className="assessment-container">
        {assessmentQuestions &&
          assessmentQuestions.map((question) => {
            return (
              <>
                <div className="tile">
                  <div className="tile__inner">
                    <div className="tile__header"></div>
                    <div className="tile__body">
                      <div>{question.name}</div>
                      <div>{renderQuestion(question)}</div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      </div>
    </>
  );
};

export default withToken(ProgramAssessmentAnswers);
