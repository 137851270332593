import React from 'react';
import {Table} from 'react-bootstrap';
import withToken from "../hocs/withToken";
import {getStats} from "../services/api";
const Start = () => {
    const [stats, setStats] = React.useState({})

    React.useEffect(()=>{
        getStats().then(setStats)
    },[])
    return (
        <>
            <h1>User stats</h1>
            <Table striped bordered hover style={{width: "400px", margin: "20px"}}>
                <thead>
                <tr>
                    <th style={{backgroundColor: "#cccccc", color: "#000000"}}>Metric</th>
                    <th style={{backgroundColor: "#cccccc", color: "#000000"}}>Value</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Logs</td>
                    <td><a href="/logs">{stats.logs}</a></td>
                </tr>
                <tr>
                    <td>Users</td>
                    <td><a href="/users">{stats.users}</a></td>
                </tr>
                <tr>
                    <td>Practices</td>
                    <td><a href="/drills">{stats.drills}</a></td>
                </tr>
                <tr>
                    <td>Programs</td>
                    <td><a href="/programs">{stats.programs}</a></td>
                </tr>
                <tr>
                    <td>Groups</td>
                    <td><a href="/groups">{stats.groups}</a></td>
                </tr>
                </tbody>
            </Table>
        </>
    );
}

export default withToken(Start)