import React from "react";
import { getDrills, deleteDrill } from "../../services/api";
import { useAlert } from "react-alert";
import withToken from "../../hocs/withToken";
import { setOrder } from "../../services/api";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../components/Confirm";
import Button from "@mui/material/Button";

const DrillsList = () => {
  const history = useHistory();
  const [drills, setDrills] = React.useState([]);
  const [confirmState, setConfirmState] = React.useState({ open: false });
  const alert = useAlert();

  const editHandler = (id) => {
    window.location.href = "/drills/" + id;
  };

  const deleteHandler = (id) => {
    setTimeout(() => {
      deleteDrill(id)
        .then(() => {
          refreshData();
        })
        .catch((error) => alert.show(error));
    }, 500);
  };

  const deleteConfirm = (params) => {
    setConfirmState({
      open: true,
      id: params.id,
      text: 'Do you really want to delete "' + params.row.name + '" practice?',
    });
  };

  const orderHandler = (id, direction) => {
    setOrder(id, direction).then(() => {
      getDrills(0, 1000)
        .then(setDrills)
        .catch((error) => alert.show(error));
    });
  };

  React.useEffect(() => {
    refreshData();
  }, [alert]);

  const refreshData = (_) => {
    getDrills(0, 1000)
      .then((drills) => {
        setDrills(Object.values(drills));
      })
      .catch((error) => alert.show(error));
  };

  const columns = [
    { field: "id", headerName: "Id", width: 70 },
    {
      field: "name",
      headerName: "Name",
      flex: 2,
    },
    {
      field: "is_active",
      headerName: "Active",
      flex: 1,
      type: "boolean",
    },
    {
      field: "old_users_only",
      headerName: "Old users only",
      flex: 1,
      type: "boolean",
    },
    {
      field: "drill_order",
      headerName: "Order",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => editHandler(params.id)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => deleteConfirm(params)}
        />,
      ],
    },
  ];

  return (
    <>
      <h3 className="d-flex justify-content-between">
        <div>List of practices</div>
        <Button
          variant="contained"
          onClick={() => {
            history.push("/drills-new");
          }}
        >
          Add practice
        </Button>
      </h3>
      <DataGrid
        rows={drills}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        autoHeight
      />
      <ConfirmDialog
        state={confirmState}
        setConfirmState={setConfirmState}
        confirmCallback={deleteHandler}
      ></ConfirmDialog>
    </>
  );
};

export default withToken(DrillsList);
