import React from "react";
import {
  getProgram,
  getProgramStatuses,
  updateProgram,
  createProgram
} from "../../services/api";
import { useAlert } from "react-alert";
import withToken from "../../hocs/withToken";
import { showError } from "../../utils";
import ProgramGroupsManagement from "../../components/ProgramGroupsManagement";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import TinyMceEditor from "../../components/TinyMceTextEditor";

const ProgramsEdit = () => {
  const alert = useAlert();

  const [program, setProgram] = React.useState({});
  const [startedDate, setStartedDate] = React.useState(moment());
  const [finishedDate, setFinishedDate] = React.useState(moment().add(7, 'days'));
  const [statuses, setStatuses] = React.useState([]);
  const [status, setStatus] = React.useState("active");
  const [programName, setProgramName] = React.useState("");
  const [programDescription, setProgramDescription] = React.useState("");
  const [programUsersCount, setprogramUsersCount] = React.useState(2);
  const { id } = useParams();

  const saveProgram = (_) => {
    if (id === "0") {
      createProgram(
        programName,
        programDescription,
        moment(startedDate).format('YYYY-MM-DD'),
        moment(finishedDate).format('YYYY-MM-DD'),
        status,
        programUsersCount
      ).then((response) => {
        response.id
          ? (window.location.href = "/programs")
          : showError(alert, response);
      });
    } else {
      updateProgram(
        id,
        programName,
        programDescription,
        moment(startedDate).format('YYYY-MM-DD'),
        moment(finishedDate).format('YYYY-MM-DD'),
        status,
        programUsersCount
      ).then((response) => {
        response.id
          ? (window.location.href = "/programs")
          : showError(alert, response);
      });
    }
  };

  React.useEffect(() => {
    if (id !== "0") {
      getProgram(id).then((program) => {
        setProgram(program);
        setStartedDate(moment(program.started));
        setFinishedDate(moment(program.finished));
        setStatus(program.status);
        setProgramName(program.name);
        setProgramDescription(program.description);
        setprogramUsersCount(program.group_users_count);
      });
    }

    getProgramStatuses().then((stat) => {
      setStatuses([stat[0], stat[1]]);
    });
  }, [id]);

  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <div>
          <h4>{id == 0 ? "Create" : "Edit"} program</h4>
        </div>
        <div className="d-flex justify-content-end gap-1">
          <Button variant="contained" type="submit" onClick={saveProgram}>
            {id == 0 ? "Create" : "Save"}
          </Button>
          <Button
            variant="text"
            onClick={() => (window.location.href = "/programs")}
          >
            Back to list
          </Button>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <form>
              <div className="form__container">
                <div className="d-flex gap-4 flex-column">
                  <TextField
                    value={programName}
                    onChange={(event) => {
                      setProgramName(event.target.value);
                    }}
                    className="w-100"
                    id="name"
                    label="Name"
                    variant="outlined"
                  />
                  <TextField
                    value={programUsersCount}
                    onChange={(event) => {
                      setprogramUsersCount(event.target.value);
                    }}
                    className="w-100"
                    id="groupSize"
                    label="Default group size"
                    variant="outlined"
                    type="number"
                  />
                  <FormControl fullWidth>
                    <InputLabel id="programStatus-label">Status</InputLabel>
                    <Select
                      labelId="programStatus-label"
                      value={status}
                      label="Status"
                      onChange={(event) => setStatus(event.target.value)}
                    >
                      {statuses.map((status) => {
                        return (
                          <MenuItem key={status} value={status}>
                            {status}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl fullWidth>
                    <DatePicker
                      labelId="startedDate-label"
                      label="Start date"
                      value={startedDate}
                      onChange={(date) => setStartedDate(date)}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <DatePicker
                      label="Finish date"
                      value={finishedDate}
                      onChange={(date) => setFinishedDate(date)}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <DatePicker
                      readOnly
                      label="Created"
                      value={moment(program.created)}
                    />
                  </FormControl>
                </div>
                <div>
                  <TinyMceEditor
                    height={460}
                    placeholder="Program description"
                    value={programDescription}
                    updateValue={setProgramDescription}
                  />
                </div>
              </div>
              <div className="mt-3">
                {id && id > 0 && <ProgramGroupsManagement programId={id} />}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default withToken(ProgramsEdit);
