import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { updateAssessment, createAssessment } from "../../services/assessments-api.js";
import { createProgramAssessment } from "../../services/program-assessment-api.js";
import * as React from "react";

const AssessmentDetailsEditor = ({ programId, assessment }) => {
  const [editState, setEditState] = React.useState({ isEdit: false });
  const [assessmentData, setAssessmentData] = React.useState({});
  const [nameFieldError, setNameFieldError] = React.useState(false);

  React.useEffect(() => {
    if (assessment) {
      setAssessmentData(assessment);

      if (assessment.id === "0") {
        edit();
      }
    }
  }, [assessment]);

  const edit = () => {
    setEditState({
      isEdit: true,
      name: assessment.name,
      description: assessment.description,
    });
  };

  const save = () => {
    if (assessment.id === "0") {
      if (assessmentData.name === "") {
        setNameFieldError(true);
      } else {
        if (programId) {
          createProgramAssessment(programId, {
            available_at: 0,
            assessment: assessmentData,
          }).then((programAssessment) => {
            window.location.href = `/programs/${programAssessment.program_id}/assessments/${programAssessment.assessment_id}`;
          });
        } else {
          createAssessment(assessmentData.name, assessmentData.description, true).then((createdAssessment) => {
            window.location.href = `/assessments/templates/${createdAssessment.id}`;
          });
        }
      }
    } else {
      updateAssessment(
        assessment.id,
        assessmentData.name,
        assessmentData.description,
        !!!programId
      ).then(() => {
        setEditState(null);
      });
    }
  };

  const cancel = () => {
    if (editState) {
      setAssessmentData(editState);
      setEditState(null);
    }
  };

  return (
    <>
      <div className="tile">
        <div className="tile__inner">
          <div className="tile__inner-content">
            <div className="tile__header">
              <TextField
                className="assessment-question__content-text"
                variant="standard"
                disabled={!editState?.isEdit}
                value={assessmentData.name}
                placeholder="Assessment name"
                error={nameFieldError}
                onChange={(event) => {
                  let assessmentData_local = { ...assessmentData };
                  assessmentData_local.name = event.target.value;
                  setAssessmentData(assessmentData_local);
                }}
              />
              <div className="tile__body-action">
                {editState?.isEdit ? (
                  <>
                    <SaveIcon onClick={() => save()} />
                    {assessment.id !== '0' && <CancelIcon onClick={() => cancel()} />}
                  </>
                ) : (
                  <>
                    <EditIcon
                      onClick={(_) => {
                        edit();
                      }}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="tile__body">
              <TextField
                className="assessment-question__content-text"
                variant="standard"
                multiline
                disabled={!editState?.isEdit}
                value={assessmentData.description}
                placeholder="Assessment description"
                onChange={(event) => {
                  let assessmentData_local = { ...assessmentData };
                  assessmentData_local.description = event.target.value;
                  setAssessmentData(assessmentData_local);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssessmentDetailsEditor;
