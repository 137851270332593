import React from "react";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button'
import { useHistory } from "react-router-dom";

export default function From({
                                 title,
                                 button,
                                 submitHandle,
                                 refUsername,
                                 refEmail,
                                 refPassword,
                                 isSuperUser,
                                 handleIsSuperUserChange,
                                 isSupporter,
                                 handleIsSupporter,
                                 isActive,
                                 handleIsActiveChange,
                                 isOld,
                                 handleIsOldChange,
                                 emailConfirmed,
                                 handleEmailConfirmedChange,
                                 multiGroupsAvailable,
                                 handleMultiGroupsAvailable
                             }) {
    const history = useHistory();

    return (
        <><h3>{title}</h3>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={submitHandle}>
                            <div className="form-row mt-4">
                                <div className="col-md-6 d-flex flex-column gap-1">
                                    <TextField inputRef={refUsername} id="full-name" label="Full Name" variant="outlined" />
                                    <TextField inputRef={refEmail} id="email" label="Email" variant="outlined" autoComplete="off" />
                                    <TextField inputRef={refPassword} type="password" id="Password" label="Password" variant="outlined" autoComplete="off" />
                                    <div>
                                        <FormControlLabel control={<Checkbox onChange={handleIsSuperUserChange} checked={isSuperUser} id="isSuperuser" variant="outlined" />} label="Is superuser" />
                                        <FormControlLabel control={<Checkbox onChange={handleIsSupporter} checked={isSupporter} id="isSupporter" variant="outlined" />} label="Is supporter" />
                                        <FormControlLabel control={<Checkbox onChange={handleIsActiveChange} checked={isActive} id="isActive" variant="outlined" />} label="Is active" />
                                        <FormControlLabel control={<Checkbox onChange={handleIsOldChange} checked={isOld} id="isOld" variant="outlined" />} label="Is old" />
                                        <FormControlLabel control={<Checkbox onChange={handleEmailConfirmedChange} checked={emailConfirmed} id="emailConfirmed" variant="outlined" />} label="Email confirmed" />
                                        <FormControlLabel control={<Checkbox onChange={handleMultiGroupsAvailable} checked={multiGroupsAvailable} id="multiGroupsAvailable" variant="outlined" />} label="Multiple groups available" />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <Button type="submit" variant="contained">{button}</Button>
                                    <Button variant="standard" onClick={() => history.goBack()}>Cancel</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}