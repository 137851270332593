import { GET, POST, PUT, DELETE } from "./api";

const getAssessmentQuestions = (assessment_id) => {
  return GET("/assessments/" + assessment_id + "/questions/");
};

const updateQuestionsOrder = (assessment_id, questions) => {
  return POST("/assessments/" + assessment_id + "/questions/order", questions);
};

const createAssessmentQuestion = (assessment_id, question) => {
  return POST("/assessments/" + assessment_id + "/questions/", question);
};

const updateAssessmentQuestion = (assessment_id, question) => {
  return PUT("/assessments/" + assessment_id + "/questions/" + question.id, question);
};

const deleteAssessmentQuestion = (assessment_id, question_id) => {
  return DELETE("/assessments/" + assessment_id + "/questions/" + question_id);
}

export {
    getAssessmentQuestions,
    createAssessmentQuestion,
    updateQuestionsOrder,
    updateAssessmentQuestion,
    deleteAssessmentQuestion
}