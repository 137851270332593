import React from "react";
import {
  createUserGroup,
  generateGroupForProgram,
  deleteGroup,
  updateGroup_from_object,
} from "../../../services/api";
import Stack from "@mui/material/Stack";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ConfirmDialog from "../../Confirm";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { getSupporters } from "../../../services/users-api";

export default function UserGroupsManagement({
  groups,
  setGroups,
  refreshData,
  program_id,
}) {
  const [confirmState, setConfirmState] = React.useState({ open: false });
  const [supporters, setSupporters] = React.useState([]);

  const updateGroupSupporter = (event, group) => {
    group.supporter_id = event.target.value;
    updateGroup_from_object(group).then((_) => {
      let groups_copy = [...groups];
      let group_index = groups_copy.findIndex((g) => g.id == group.id);
      groups_copy[group_index] = group;
      setGroups(groups_copy);
    });
  };

  React.useEffect(() => {
    getSupporters().then((supporters) => {
      setSupporters(supporters);
    });
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    margin: "1rem",
    textAlign: "center",
  }));

  const deleteHandler = (id) => {
    setTimeout(() => {
      deleteGroup(id)
        .then(() => {
          refreshData();
        })
        .catch((error) => alert.show(error));
    }, 500);
  };

  const deleteConfirm = (id) => {
    setConfirmState({
      open: true,
      id: id,
      text: "Do you really want to delete this group?",
    });
  };

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.droppableId === result.source.droppableId) {
      return;
    }

    const user_id = parseInt(result.draggableId);
    const new_group_id = parseInt(result.destination.droppableId);
    const old_group_id = parseInt(result.source.droppableId);

    const source_group = groups.filter((f) => f.id === old_group_id)[0];
    const destination_group = groups.filter((f) => f.id === new_group_id)[0];
    const user = source_group.users.filter((u) => u.user_id === user_id)[0];
    destination_group.users.push(user);
    source_group.users = source_group.users.filter(
      (u) => u.user_id !== user_id
    );

    createUserGroup(user_id, new_group_id).then((response) => {
      refreshData();
    });
  }

  function generateGroup() {
    generateGroupForProgram(program_id).then((response) => {
      refreshData();
    });
  }

  return (
    <div className="mt-3 mb-3">
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-cent justify-content-center flex-grow-1">
          Please use drag and drop to move users between groups
        </div>
        <div>
          <Button
            color="primary"
            className="pointer"
            startIcon={<AddIcon />}
            onClick={generateGroup}
          >
            Generate new group
          </Button>
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="d-flex gap-1 flex-wrap">
          {groups &&
            groups.map((group) => {
              return (
                <div key={group.id}>
                  <div
                    style={{
                      width: "23%",
                      minWidth: "150px",
                      maxWidth: "240px",
                    }}
                  >
                    <div
                      className="d-flex justify-content-between m-3"
                      style={{ minHeight: "28px" }}
                    >
                      <div className="d-flex align-items-center">
                        {group.name}
                      </div>
                      {!group.users?.length && (
                        <div className="pointer">
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteConfirm(group.id)}
                            size="small"
                            disableRipple
                            value={2}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </div>
                      )}
                    </div>
                    {supporters && (
                      <Box sx={{ marginBottom: "1rem" }}>
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Supporter
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Supporter"
                            onChange={(event) =>
                              updateGroupSupporter(event, group)
                            }
                            value={group.supporter_id ?? ""}
                          >
                            <MenuItem value={null} key="null_supporter">
                              None
                            </MenuItem>
                            {supporters.map((supporter) => {
                              return (
                                <MenuItem
                                  value={supporter.id}
                                  key={group.id + "_" + supporter.id}
                                >
                                  {supporter.full_name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    <Droppable
                      droppableId={"" + group.id}
                      key={"droppable_" + group.id}
                    >
                      {(provided) => (
                        <Stack
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          style={{
                            height: "450px",
                            border: "1px dashed grey",
                            overflowY: "scroll",
                          }}
                        >
                          <div>
                            {provided.placeholder}
                            {group.users &&
                              group.users.map((user, i) => {
                                return (
                                  <Draggable
                                    key={"draggable_" + user.user_id}
                                    draggableId={"" + user.user_id}
                                    index={i + 10}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {(provided) => (
                                      <Item
                                        key={"draggable_item_" + user.user_id}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                      >
                                        {user.user.full_name}
                                      </Item>
                                    )}
                                  </Draggable>
                                );
                              })}
                          </div>
                        </Stack>
                      )}
                    </Droppable>
                  </div>
                </div>
              );
            })}
        </div>
      </DragDropContext>
      <ConfirmDialog
        state={confirmState}
        setConfirmState={setConfirmState}
        confirmCallback={deleteHandler}
      ></ConfirmDialog>
    </div>
  );
}
