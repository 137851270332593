import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmDialog({
  state,
  setConfirmState,
  confirmCallback,
}) {
  const confirm = () => {
    confirmCallback && confirmCallback(state.id);
    setConfirmState({ open: false });
  };
  return (
    <div>
      <Dialog
        open={state.open}
        onClose={(_) => setConfirmState({ open: false })}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent>{state.text}</DialogContent>
        <DialogActions>
          <Button onClick={(_) => setConfirmState({ open: false })} autoFocus>
            Cancel
          </Button>
          <Button onClick={confirm} variant="contained" color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
