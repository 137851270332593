import React from "react";
import {
  getDrills,
  getLog,
  getQualities,
  getResistances,
  getUsers,
  updateLog,
  createLog,
} from "../../services/api";
import { useAlert } from "react-alert";
import withToken from "../../hocs/withToken";
import { showError } from "../../utils";
import { useParams } from "react-router-dom";
import moment from "moment";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TinyMceEditor from "../../components/TinyMceTextEditor";

const DrillsEdit = () => {
  const [user_id, setUserId] = React.useState(1);
  const [drill_id, setDrillId] = React.useState(1);
  const [quality, setQuality] = React.useState("");
  const [resistance, setResistance] = React.useState("");
  const [reflection, setReflection] = React.useState("");
  const [users, setUsers] = React.useState([]);
  const [drills, setDrills] = React.useState([]);
  const [qualities, setQualities] = React.useState([]);
  const [resistances, setResistances] = React.useState([]);
  const [finished, setFinished] = React.useState(moment());
  const [log_date, setLogDate] = React.useState(moment());
  const { id } = useParams();
  const alert = useAlert();

  const saveLog = () => {
    if (id === "0") {
      createLog(
        user_id,
        drill_id,
        reflection,
        quality,
        resistance,
        finished,
        moment(log_date).format('YYYY-MM-DD')
      ).then((response) => {
        response.user_id
          ? (window.location.href = "/logs")
          : showError(alert, response);
      });
    } else {
      updateLog(
        id,
        user_id,
        drill_id,
        reflection,
        quality,
        resistance,
        finished,
        moment(log_date).format('YYYY-MM-DD')
      ).then((response) => {
        response.user_id
          ? (window.location.href = "/logs")
          : showError(alert, response);
      });
    }
  };

  React.useEffect(() => {
    getQualities()
      .then((q) => setQualities(Object.values(q)))
      .catch((error) => alert.show(error));
    getResistances()
      .then((r) => setResistances(Object.values(r)))
      .catch((error) => alert.show(error));
    getUsers()
      .then((users) => setUsers(Object.values(users)))
      .catch((error) => alert.show(error));
    getDrills()
      .then((drills) => setDrills(Object.values(drills)))
      .catch((error) => alert.show(error));

    if (id !== "0") {
      getLog(id).then((log) => {
        setUserId(log.user_id);
        setDrillId(log.drill_id);
        setReflection(log.reflection);
        setQuality(log.quality);
        setResistance(log.resistance);
        setFinished(moment(log.finished));
        setLogDate(moment(log.log_date));
      });
    }
  }, [id]);

  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <div>
          <h4>{id == 0 ? "Create" : "Edit"} log</h4>
        </div>
        <div className="d-flex justify-content-end gap-1">
          <Button variant="contained" type="submit" onClick={saveLog}>
            {id == 0 ? "Create" : "Save"}
          </Button>
          <Button
            variant="text"
            onClick={() => (window.location.href = "/logs")}
          >
            Back to list
          </Button>
        </div>
      </div>
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <form>
              <div className="form__container">
                <div className="d-flex gap-4 flex-column">
                  <FormControl fullWidth>
                    <InputLabel id="user-label">User</InputLabel>
                    <Select
                      labelId="user-label"
                      value={user_id}
                      label="User"
                      onChange={(event) => setUserId(event.target.value)}
                    >
                      {users?.map((user) => {
                        return (
                          <MenuItem key={user.id} value={user.id}>
                            {user.full_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="drill-label">Drill</InputLabel>
                    <Select
                      labelId="drill-label"
                      value={drill_id}
                      label="Drill"
                      onChange={(event) => setDrillId(event.target.value)}
                    >
                      {drills?.map((drill) => {
                        return (
                          <MenuItem key={drill.id} value={drill.id}>
                            {drill.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="quality-label">Quality</InputLabel>
                    <Select
                      labelId="quality-label"
                      value={quality}
                      label="Quality"
                      onChange={(event) => setQuality(event.target.value)}
                    >
                      {qualities?.map((quality) => {
                        return (
                          <MenuItem key={quality.name} value={quality.name}>
                            {quality.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <InputLabel id="resistance-label">Resistance</InputLabel>
                    <Select
                      labelId="resistance-label"
                      value={resistance}
                      label="Resistance"
                      onChange={(event) => setResistance(event.target.value)}
                    >
                      {resistances?.map((resistance) => {
                        return (
                          <MenuItem key={resistance.name} value={resistance.name}>
                            {resistance.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth>
                    <DatePicker
                      labelId="FinishedDate-label"
                      label="Finished"
                      value={finished}
                      onChange={(date) => setFinished(date)}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <DatePicker
                      labelId="logDate-label"
                      label="Log date"
                      value={log_date}
                      onChange={(date) => setLogDate(date)}
                    />
                  </FormControl>
                </div>
                <div>
                  <TinyMceEditor
                    height={460}
                    placeholder="Reflection"
                    value={reflection}
                    updateValue={setReflection}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default withToken(DrillsEdit);
