import { useEffect, useState } from "react";
import withToken from "../../hocs/withToken";
import { useHistory, useParams } from "react-router-dom";
import { getAssessmentTemplates, deleteAssessment } from "../../services/assessments-api";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import ConfirmDialog from "../../components/Confirm";

const AssessmentTemplates = () => {
  const history = useHistory();
  const [assessments, setAssessments] = useState([]);
  const [confirmState, setConfirmState] = useState({ open: false });

  const goToDetailsPage = (id) => {
    history.push(`/assessments/templates/${id}`);
  };

  useEffect(() => {
    refreshData();
  }, []);

  const deleteConfirm = (params) => {
    setConfirmState({
      open: true,
      id: params.id,
      text: 'Do you really want to delete "' + params.row.name + '" assessment?',
    });
  };

  const deleteHandler = (id) => {
    setTimeout(() => {
      deleteAssessment(id)
        .then(() => {
          refreshData();
        })
        .catch((error) => alert.show(error));
    }, 500);
  };

  const refreshData = () => {
    getAssessmentTemplates().then((assessments) => {
      setAssessments(assessments);
    });
  }

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Assessment",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={() => {
              goToDetailsPage(params.row.id);
            }}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => deleteConfirm(params)}
          />,
        ];
      },
    },
  ];

  return (
    <>
      <h3 className="d-flex justify-content-between">
        <div>List of assessment templates</div>
        <div className="d-flex gap-1">
          <Button
            variant="contained"
            onClick={() => {
              goToDetailsPage(0);
            }}
          >
            Add assessment template
          </Button>
        </div>
      </h3>
      <DataGrid
        rows={assessments}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        autoHeight
      />
      <ConfirmDialog
        state={confirmState}
        setConfirmState={setConfirmState}
        confirmCallback={deleteHandler}
      ></ConfirmDialog>
    </>
  );
};

export default withToken(AssessmentTemplates);
