import React from "react";
import { getUsers, deleteUser } from "../../services/api";
import { useAlert } from "react-alert";
import withToken from "../../hocs/withToken";
import { deleteRow, showError } from "../../utils";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../components/Confirm";
import Button from "@mui/material/Button";

const UsersList = () => {
  const history = useHistory();
  const [users, setUsers] = React.useState([]);
  const [confirmState, setConfirmState] = React.useState({ open: false });
  
  const alert = useAlert();

  const editHandler = (id) => {
    window.location.href = "/users/" + id;
  };

  const refreshData = (_) => {
    getUsers(0, 1000)
      .then(setUsers)
      .catch((error) => alert.show(error));
  };

  const deleteHandler = (id) => {
    setTimeout(() => {
      deleteUser(id)
        .then(() => {
          refreshData();
        })
        .catch((error) => alert.show(error));
    }, 500);
  };

  React.useEffect(() => {
    refreshData();
  }, [alert]);

  const deleteConfirm = (params) => {
    let text = (
      <div>You're about to remove a "{params.row.full_name} ({params.row.email})", their program and their logs. <br />
      Are you sure you want to do it?</div>
    );

    setConfirmState({
      open: true,
      id: params.id,
      text: text,
    });
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "full_name",
      headerName: "Full name",
      flex: 2,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 3,
    },
    {
      field: "is_superuser",
      headerName: "Superuser",
      flex: 1,
      type: "boolean",
    },
    {
      field: "is_supporter",
      headerName: "Supporter",
      flex: 1,
      type: "boolean",
    },
    {
      field: "is_active",
      headerName: "Active",
      flex: 1,
      type: "boolean",
    },
    {
      field: "is_old",
      headerName: "Old",
      flex: 1,
      type: "boolean",
    },
    {
      field: "email_confirmed",
      headerName: "Email confirmed",
      flex: 1,
      type: "boolean",
    },
    {
      field: "multi_groups_available",
      headerName: "Multi groups available",
      flex: 1,
      type: "boolean",
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => editHandler(params.id)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => deleteConfirm(params)}
        />,
      ],
    },
  ];

  let usersArray = Object.values(users);

  return (
    <>
      <h3 className="d-flex justify-content-between">
        <div>List of users</div>
        <Button
          variant="contained"
          onClick={() => {
            history.push("/users-new");
          }}
        >
          Add user
        </Button>
      </h3>
      <DataGrid
        rows={usersArray}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        autoHeight
      />
      <ConfirmDialog
        state={confirmState}
        setConfirmState={setConfirmState}
        confirmCallback={deleteHandler}
      ></ConfirmDialog>
    </>
  );
};

export default withToken(UsersList);
