import React from "react";
import { getPrograms, deleteProgram, getProgramGroups } from "../../services/api";
import { useAlert } from "react-alert";
import withToken from "../../hocs/withToken";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssessmentItem from "@mui/icons-material/Assessment"
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../components/Confirm";
import Button from "@mui/material/Button";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const ProgramsList = () => {
  const history = useHistory();
  const [programs, setPrograms] = React.useState([]);
  const [confirmState, setConfirmState] = React.useState({ open: false });

  const alert = useAlert();

  const assessmentsEditHandler = (id) => {
    window.location.href = "/programs/" + id + "/assessments";
  };

  const editHandler = (id) => {
    window.location.href = "/programs/" + id;
  };

  const deleteHandler = (id) => {
    setTimeout(() => {
      deleteProgram(id)
        .then(() => {
          refreshData();
        })
        .catch((error) => alert.show(error));
    }, 500);
  };

  const deleteConfirm = (program) => {
    getProgramGroups(program.id).then((groups) => {
      let text = 
      <div>
        Do you really want to delete "{program.row.name}" program?
        {groups.length > 0 && 
        <div className="mt-3">
          Groups in this program:
          <List dense={true}>
              {groups.map(g => 
                <ListItem>
                  <ListItemText
                    primary={g.name}
                    secondary={'Users count: ' + (g.users.length ?? 0)}
                  />
                </ListItem>,
              )}
          </List>
        </div>}
      </div>

      setConfirmState({
        open: true,
        id: program.id,
        text: text,
      });
    })
  };

  const refreshData = (_) => {
    getPrograms(0, 1000)
      .then((programs) => {
        setPrograms(programs);
      })
      .catch((error) => alert.show(error));
  };

  React.useEffect(() => {
    refreshData();
  }, [alert]);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Program",
      flex: 1,
    },
    {
      field: "started",
      headerName: "Started",
      flex: 1,
      valueFormatter: (params) => new Date(params?.value).toLocaleDateString(),
    },
    {
      field: "finished",
      headerName: "Finished",
      flex: 1,
      valueFormatter: (params) => new Date(params?.value).toLocaleDateString(),
    },
    {
      field: "created",
      headerName: "Created",
      flex: 1,
      valueFormatter: (params) => new Date(params?.value).toLocaleDateString(),
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<AssessmentItem />}
          label="Assessments"
          onClick={() => assessmentsEditHandler(params.id)}
        />,
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Edit"
          onClick={() => editHandler(params.id)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => deleteConfirm(params)}
        />,
      ],
    },
  ];

  return (
    <>
      <h3 className="d-flex justify-content-between">
        <div>List of programs</div>
        <Button
          variant="contained"
          onClick={() => {
            editHandler(0);
          }}
        >
          Add program
        </Button>
      </h3>
      <DataGrid
        rows={programs}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        autoHeight
      />
      <ConfirmDialog
        state={confirmState}
        setConfirmState={setConfirmState}
        confirmCallback={deleteHandler}
      ></ConfirmDialog>
    </>
  );
};

export default withToken(ProgramsList);
