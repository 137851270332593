import withToken from "../../hocs/withToken";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAssessment } from "../../services/assessments-api";
import {
  updateAssessmentQuestion,
  createAssessmentQuestion,
  updateQuestionsOrder,
  deleteAssessmentQuestion,
} from "../../services/assessment-questions-api";
import { saveAssessmentAsTemplate } from "../../services/prorgrams-api";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicator from "@mui/icons-material/DragIndicator";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ConfirmDialog from "../../components/Confirm";
import AddQuestionButton from "../../components/assessments/addQuestionButton";
import AssessmentDetailsEditor from "../../components/assessments/assessmentDetailsEditor";

import "../../assets/scss/components/assessment-details.scss";

const ProgramAssessmentDetails = () => {
  const { id } = useParams();
  const { programId } = useParams();
  const [assessment, setAssessment] = useState({});
  const [editItem, setEditItem] = useState({});
  const [confirmState, setConfirmState] = useState({ open: false });

  useEffect(() => {
    if (id !== "0") {
      getAssessment(id).then(setAssessment);
    } else {
      setAssessment({
        name: "",
        description: "",
        id: "0",
        questions: [],
      });
    }
  }, [id]);

  const marks = [];

  for (let i = 1; i <= 10; i++) {
    marks.push({
      value: i,
      label: i,
    });
  }

  const addQuestion = (type) => {
    if (editItem) {
      cancelEdit();
    }

    let assessment_local = { ...assessment };

    let question = {
      type_id: type,
      id: 0,
      name: "",
      order:
        assessment.questions.length > 0
          ? Math.max(...assessment.questions.map((q) => q.order)) + 1
          : 1,
      options: [],
    };

    if (type === 2) {
      question.options = [
        {
          id: 0,
          order: 0,
          text: "",
        },
        {
          id: 0,
          order: 1,
          text: "",
        },
      ];
    }

    setEditItem(question);

    assessment_local.questions.push(question);
    setAssessment(assessment_local);
  };

  const deleteHandler = (id) => {
    setTimeout(() => {
      deleteAssessmentQuestion(assessment.id, id).then(() => {
        let assessment_local = { ...assessment };
        let index = assessment_local.questions.findIndex(
          (q) => q.id == editItem.id
        );
        assessment_local.questions.splice(index, 1);
        setAssessment(assessment_local);
      });
    }, 500);
  };

  const deleteQuestion = (question) => {
    setConfirmState({
      open: true,
      id: question.id,
      text: `Are you sure you want to delete the question: '${question.name}'?`,
    });
  };

  const cancelEdit = () => {
    let assessment_local = { ...assessment };
    let index = assessment.questions.findIndex((q) => q.id == editItem.id);
    if (editItem.id !== 0) {
      assessment_local.questions[index] = editItem;
    } else if (editItem.id === 0) {
      assessment_local.questions.splice(index, 1);
    }
    setAssessment(assessment_local);
    setEditItem({});
  };

  const saveItem = () => {
    if (editItem.id === undefined) return;

    if (editItem.id !== 0) {
      let index = assessment.questions.findIndex((q) => q.id == editItem.id);

      updateAssessmentQuestion(assessment.id, assessment.questions[index]).then(
        (question) => {
          assessment.questions[index] = question;
          setEditItem({});
        }
      );
    } else if (editItem.id === 0) {
      let index = assessment.questions.findIndex((q) => q.id == editItem.id);

      createAssessmentQuestion(assessment.id, assessment.questions[index]).then(
        (question) => {
          assessment.questions[index] = question;
          setEditItem({});
        }
      );
    }
  };

  const saveAsTemplate = () => {
    saveAssessmentAsTemplate(programId, assessment.id).then((assessment) => {
      window.location.href = `/assessments/templates/${assessment.id}`;
    });
  };

  const renderQuestion = (question, editMode) => {
    switch (question.type_id) {
      case 1:
        return (
          <>
            <TextField
              className="assessment-question__content-text"
              variant="filled"
              multiline
              placeholder="Your answer"
              disabled
              size="small"
            />
          </>
        );
      case 2:
        return (
          <>
            <div className="assessment-question__content-slider">
              <>
                <div className="assessment-question__content-slider__desktop">
                  <div className="label">
                    <TextField
                      className="assessment-question__content-text"
                      variant="standard"
                      multiline
                      placeholder="Your answer"
                      disabled={!editMode}
                      value={question.options[0].text}
                      onChange={(event) => {
                        let assessment_local = { ...assessment };
                        let index = assessment.questions.findIndex(
                          (q) => q.id == editItem.id
                        );
                        assessment_local.questions[index].options[0].text =
                          event.target.value;

                        setAssessment(assessment_local);
                      }}
                    />
                  </div>
                  <Box className="slider">
                    <Slider
                      valueLabelDisplay="auto"
                      step={1}
                      min={1}
                      max={10}
                      marks={marks}
                      value={5}
                      disabled
                    />
                  </Box>
                  <div className="label">
                    <TextField
                      className="assessment-question__content-text"
                      variant="standard"
                      multiline
                      placeholder="Your answer"
                      disabled={!editMode}
                      value={question.options[1].text}
                      onChange={(event) => {
                        let assessment_local = { ...assessment };
                        let index = assessment.questions.findIndex(
                          (q) => q.id == editItem.id
                        );
                        assessment_local.questions[index].options[1].text =
                          event.target.value;

                        setAssessment(assessment_local);
                      }}
                    />
                  </div>
                </div>
              </>
            </div>
          </>
        );
    }
  };

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    for (let i = 0; i < result.length; i++) {
      result[i].order = i + 1;
    }

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    assessment.questions = reorder(
      assessment.questions,
      result.source.index,
      result.destination.index
    );

    updateQuestionsOrder(assessment.id, assessment.questions);

    setAssessment(assessment);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    margin: `1rem 0`,
    ...draggableStyle,
  });

  return (
    <>
      <div className="d-flex justify-content-between m-3">
        <div>
          <h4>Assessment details</h4>
        </div>
        <div className="d-flex justify-content-end gap-1">
          {assessment?.id !== "0" && (
            <Button
              variant="contained"
              onClick={() => {
                saveItem();
                if (programId) {
                  window.location.href = `/programs/${programId}/assessments`;
                } else {
                  window.location.href = `/assessments/templates`;
                }
              }}
            >
              Save
            </Button>
          )}
          {programId && assessment?.id !== "0" && (
            <Button
              variant="contained"
              onClick={() => {
                saveAsTemplate();
              }}
            >
              Save as template
            </Button>
          )}
          <Button
            variant="text"
            onClick={() => {
              if (programId) {
                window.location.href = `/programs/${programId}/assessments`;
              } else {
                window.location.href = `/assessments/templates`;
              }
            }}
          >
            Back to list
          </Button>
        </div>
      </div>
      {assessment && (
        <>
          <div className="assessment-container">
            <AssessmentDetailsEditor
              assessment={assessment}
              programId={programId}
            />
            {assessment?.id !== "0" && (
              <AddQuestionButton addQuestion={addQuestion} />
            )}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="droppable"
                key={"droppable_" + assessment.id}
              >
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {assessment?.id &&
                      assessment?.questions &&
                      assessment.questions
                        .sort((a, b) => {
                          return a.order - b.order;
                        })
                        .map((question, index) => {
                          const editMode = editItem.id === question.id;
                          return (
                            <>
                              <Draggable
                                key={question.id}
                                draggableId={"question_" + question.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    <div className="tile">
                                      <div className="tile__inner">
                                        <div className="tile__inner-drag">
                                          <DragIndicator />
                                        </div>
                                        <div className="tile__inner-content">
                                          <div className="tile__header">
                                            <TextField
                                              className="assessment-question__content-text"
                                              variant="standard"
                                              multiline
                                              placeholder="Your answer"
                                              disabled={!editMode}
                                              value={question.name}
                                              onChange={(event) => {
                                                let assessment_local = {
                                                  ...assessment,
                                                };
                                                assessment_local.questions[
                                                  index
                                                ].name = event.target.value;

                                                setAssessment(assessment_local);
                                              }}
                                            />
                                            {editMode ? (
                                              <div className="tile__body-action">
                                                <SaveIcon
                                                  onClick={() => saveItem()}
                                                />
                                                <CancelIcon
                                                  onClick={() => cancelEdit()}
                                                />
                                              </div>
                                            ) : (
                                              <div className="tile__body-action">
                                                <EditIcon
                                                  onClick={(_) => {
                                                    cancelEdit();
                                                    setEditItem(
                                                      structuredClone(question)
                                                    );
                                                  }}
                                                />
                                                <DeleteIcon
                                                  onClick={(_) => {
                                                    deleteQuestion(question);
                                                  }}
                                                ></DeleteIcon>
                                              </div>
                                            )}
                                          </div>
                                          <div className="tile__body">
                                            {renderQuestion(question, editMode)}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            </>
                          );
                        })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {assessment?.id && assessment.questions?.length > 2 && (
              <AddQuestionButton addQuestion={addQuestion} />
            )}
          </div>
        </>
      )}
      <ConfirmDialog
        state={confirmState}
        setConfirmState={setConfirmState}
        confirmCallback={deleteHandler}
      ></ConfirmDialog>
    </>
  );
};

export default withToken(ProgramAssessmentDetails);
