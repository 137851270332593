import React from "react";
import { getDrill, updateDrill } from "../../services/api";
import withToken from "../../hocs/withToken";
import Form from "../../components/pages/drills/form";

const DrillsEdit = () => {
  const [id, setID] = React.useState(false);
  const [name, setName] = React.useState('');
  const [order, setOrder] = React.useState(0);
  const [description, setDescription] = React.useState('');
  const [isActive, setIsActive] = React.useState(false);
  const [oldUsersOnly, setOldUsersOnly] = React.useState(false);

  const handleIsActiveChange = (event) => {
    setIsActive(event.target.checked);
  };

  const handleOldUsersOnly = (event) => {
    setOldUsersOnly(event.target.checked);
  };

  const submitHandle = async (e) => {
    e.preventDefault();
    const response = await updateDrill(
      id,
      name,
      order,
      description,
      isActive,
      oldUsersOnly
    );

    window.location.href = "/drills";
  };

  React.useEffect(() => {
    const current_id = window.location.pathname.split("/").at(-1);
    setID(current_id);
    getDrill(current_id).then((drill) => {
      setName(drill.name);
      setDescription(drill.description)
      setIsActive(drill.is_active);
      setOldUsersOnly(drill.old_users_only);
      setOrder(drill.drill_order)
    });
  }, []);
  return (
    <Form
      title={"Edit practice"}
      button={"Update"}
      submitHandle={submitHandle}
      name={name}
      handleNameChange={setName}
      order={order}
      handleOrderChange={setOrder}
      description={description}
      handleDescriptionChange={setDescription}
      isActive={isActive}
      handleIsActiveChange={handleIsActiveChange}
      oldUsersOnly={oldUsersOnly}
      handleOldUsersOnly={handleOldUsersOnly}
    />
  );
};

export default withToken(DrillsEdit);
