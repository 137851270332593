import React from 'react';
import {getUser, updateUser} from "../../services/api";
import {useAlert} from "react-alert";
import withToken from "../../hocs/withToken";
import {showError} from "../../utils";
import Form from '../../components/pages/users/form'
import {createAvatar} from "../../services/api";
import { useHistory } from "react-router-dom";

const UsersEdit = () => {
    const history = useHistory();
    const refUsername = React.useRef()
    const refEmail = React.useRef()
    const refPassword = React.useRef()
    const [isSuperUser, setIsSuperUser] = React.useState(false)    
    const [isSupporter, setIsSupporter] = React.useState(false)
    const [isActive, setIsActive] = React.useState(false)
    const [isOld, setIsOld] = React.useState(false)
    const [multiGroupsAvailable, setMultiGroupsAvailable] = React.useState(false)
    const [emailConfirmed, setEmailConfirmed] = React.useState(false)
    const [id, setID] = React.useState(false)
    const alert = useAlert()
    const [selectedFile, setSelectedFile] = React.useState([]);

    const handleIsSuperUserChange = (event) => {
        setIsSuperUser(event.target.checked);
    };
    const handleIsSupporterChange = (event) => {
        setIsSupporter(event.target.checked);
    };
    const handleIsActiveChange = (event) => {
        setIsActive(event.target.checked);
    };
    const handleIsOldChange = (event) => {
        setIsOld(event.target.checked);
    };
    const handleEmailConfirmedChange = (event) => {
        setEmailConfirmed(event.target.checked);
    };
    const handleMultiGroupsAvailable = (event) => {
        setMultiGroupsAvailable(event.target.checked);
    };

    const submitHandle = (e) => {
        e.preventDefault()

        updateUser(
            id,
            refUsername.current.value,
            refEmail.current.value,
            refPassword.current.value,
            isSuperUser,
            isSupporter,
            isActive,
            isOld,
            emailConfirmed,
            multiGroupsAvailable
        ).then((response) => {
            response.email === refEmail.current.value ? history.goBack() : showError(alert, response)
        })

        if (setSelectedFile) {
            createAvatar(selectedFile, id).then((response) => {
                console.log(response)
            }).catch((err) => alert.show("File Upload Error: " + err));
        }

    }

    React.useEffect(() => {
        const current_id = window.location.pathname.split("/").at(-1)
        setID(current_id)
        getUser(current_id).then((user) => {
            if (current_id === null || user.email === null) {
                window.location.href = "/users"
            } else {
                refUsername.current.value = user.full_name
                refEmail.current.value = user.email
                setIsSuperUser(user.is_superuser)
                setIsSupporter(user.is_supporter)
                setIsActive(user.is_active)
                setIsOld(user.is_old)
                setEmailConfirmed(user.email_confirmed)
                setMultiGroupsAvailable(user.multi_groups_available)
            }
        })
    }, [])

    return <Form
        title={"Edit user"}
        button={"Update"}
        submitHandle={submitHandle}
        refUsername={refUsername}
        refEmail={refEmail}
        refPassword={refPassword}
        isSuperUser={isSuperUser}
        handleIsSuperUserChange={handleIsSuperUserChange}
        isSupporter={isSupporter}
        handleIsSupporter={handleIsSupporterChange}
        isActive={isActive}
        handleIsActiveChange={handleIsActiveChange}
        isOld={isOld}
        handleIsOldChange={handleIsOldChange}
        emailConfirmed={emailConfirmed}
        handleEmailConfirmedChange={handleEmailConfirmedChange}
        multiGroupsAvailable={multiGroupsAvailable}
        handleMultiGroupsAvailable={handleMultiGroupsAvailable}
    />
}

export default withToken(UsersEdit)