import { GET, POST, PUT, DELETE } from "./api";

const getAssessment = (id) => {
  return GET("/assessments/" + id);
};

const createAssessment = (name, description, is_template) => {
  return POST("/assessments/", {
    name: name,
    description: description,
    is_template: is_template
  });
};

const updateAssessment = (id, name, description, is_template) => {
  return PUT("/assessments/" + id, {
    name: name,
    description: description,
    is_template: is_template
  });
};

const deleteAssessment = (id) => {
  return DELETE("/assessments/" + id)
}

const getAssessmentTemplates = () => {
  return GET("/assessments/templates");
};

const user_assessment_prefix = "/user/assessment";

const getUserAssessment = (id) => {
  return GET(user_assessment_prefix + "/" + id);
};

const createUserAssessment = (program_assessment_id) => {
  return POST(user_assessment_prefix, {
    program_assessment_id: program_assessment_id,
  });
};

const saveAnswer = (user_assessment_id, question_id, value) => {
  return POST(user_assessment_prefix + "/" + user_assessment_id + "/answer", {
    question_id: question_id,
    value: value,
  });
};

const updateAnswer = (user_assessment_id, answer_id, value) => {
  return PUT(user_assessment_prefix + "/" + user_assessment_id + "/answer/" + answer_id, {
    value: value,
  });
};

export {
  getAssessment,
  createAssessment,
  updateAssessment,
  deleteAssessment,
  createUserAssessment,
  getUserAssessment,
  saveAnswer,
  updateAnswer,
  getAssessmentTemplates
};
