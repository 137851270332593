import React from "react";
import withToken from "../../hocs/withToken";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import TextField from "@mui/material/TextField";
import { update, get, getByKey } from "../../services/app-settings-api";

const AppSettings = () => {
  const history = useHistory();
  const [ucCommunityLink, setUcCommunityLink] = React.useState({value: ""});
  const [ucCommunityLinkValue, setUcCommunityLinkValue] = React.useState("");
  const [alertText, setAlertText] = React.useState("");

    const save = () => {
      update(ucCommunityLink.id, ucCommunityLink.key, ucCommunityLinkValue).then(() => {
        setAlertText("Data saved");
        setTimeout(() => {
          setAlertText('');
        }, 3000);
      });
    };

  React.useEffect(() => {
    getByKey("CircleUCCommunityLink").then((setting) => {
      setUcCommunityLink(setting);
      setUcCommunityLinkValue(setting.value);
    })
  }, []);

  return (
    <>
      <h3 className="d-flex justify-content-between">
        <div>List of application settings</div>
          <Button variant="contained" type="submit" onClick={save}>
            Save
          </Button>
      </h3>
      <div className="w-25 m-auto">
        {alertText && <Alert severity="success" variant="filled">{alertText}</Alert>}
      </div>
      <div className="wordings m-3">
        <div>
          <TextField
                    className="w-50"
                    value={ucCommunityLinkValue}
                    onChange={(event) => setUcCommunityLinkValue(event.target.value)}
                    id="ucCommunityLink"
                    label="Circle UC Community link"
                    variant="outlined"
                    type="text"
                  />
        </div>
      </div>
    </>
  );
};

export default withToken(AppSettings);
