import axios, { isAxiosError } from "axios";
import { getToken } from "./token";
import { id2key } from "../utils";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const Login = (authenticate) => {
  return axios(
    {
      method: "post",
      url: "/login/access-token",
      data: authenticate,
      headers: { "Content-Type": "multipart/form-data" },
    },
    authenticate
  )
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error;
    });
};
const GET = (url) => {
  const token = getToken();
  return axios
    .get(url, {
      headers: { Authorization: "Bearer " + token },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
};
const POST = (url, payload) => {
  const token = getToken();
  return axios
    .post(url, payload, {
      headers: { Authorization: "Bearer " + token, "Content-Type": "application/json" },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
};

const PUT = (url, payload) => {
  const token = getToken();
  return axios
    .put(url, payload, {
      headers: { Authorization: "Bearer " + token },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
};
const DELETE = (url) => {
  const token = getToken();
  return axios
    .delete(url, {
      headers: { Authorization: "Bearer " + token },
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
};
const createAvatar = (file, user_id = null) => {
  const formData = new FormData();
  formData.append("file", file);
  return POST("/users/avatar/?user_id=" + user_id, formData);
};
const getQualities = () => {
  return GET("/logs/qualities");
};
const getStats = () => {
  return GET("/users/stats");
};
const getResistances = () => {
  return GET("/logs/resistances");
};
const getUserMe = () => {
  return GET("/users/me");
};
const getPagination = (url) => {
  return GET("/" + url + "/pagination");
};
const getProgramStatuses = () => {
  return GET("/programs/statuses");
};
const createUserGroup = (user_id, group_id) => {
  return POST("/user_groups/", [
    {
      group_id: group_id,
      user_id: user_id,
    },
  ]);
};
const creatUser2Group = (data) => {
  return POST("/user_groups/", data);
};
const creatUser2Programs = (data) => {
  return POST("/user_programs/", data);
};
// USERS
const createUser = (
  full_name,
  email,
  password,
  is_superuser,
  is_supporter,
  is_active,
  is_old,
  email_confirmed,
  multi_groups_available
) => {
  return POST("/users/", {
    email: email,
    is_active: is_active,
    is_superuser: is_superuser,
    is_supporter: is_supporter,
    is_old: is_old,
    full_name: full_name,
    password: password,
    email_confirmed: email_confirmed,
    multi_groups_available: multi_groups_available
  });
};
const getUsers = (skip = 0, limit = 10) => {
  return id2key(GET("/users/?skip=" + skip + "&limit=" + limit));
};
const getUser = (id) => {
  return GET("/users/" + id);
};
const updateUser = (
  id,
  full_name,
  email,
  password,
  is_superuser,
  is_supporter,
  is_active,
  is_old,
  email_confirmed,
  multi_groups_available
) => {
  return PUT("/users/" + id, {
    email: email,
    is_active: is_active,
    is_supporter: is_supporter,
    is_superuser: is_superuser,
    is_old: is_old,
    full_name: full_name,
    password: password,
    email_confirmed: email_confirmed,
    multi_groups_available: multi_groups_available
  });
};
const deleteUser = (id) => {
  return DELETE("/users/" + id);
};
// Drills
const createDrill = (name, order, description, is_active, old_users_only) => {
  return POST("/drills/", {
    name: name,
    drill_order: order,
    description: description,
    is_active: is_active,
    old_users_only: old_users_only,
  });
};
const getDrills = (skip = 0, limit = 10) => {
  return id2key(GET("/drills/?skip=" + skip + "&limit=" + limit));
};
const getDrill = (id) => {
  return GET("/drills/" + id);
};
const updateDrill = (id, name, order, description, is_active, old_users_only) => {
  return PUT("/drills/" + id, {
    name: name,
    drill_order: order,
    description: description,
    is_active: is_active,
    old_users_only: old_users_only,
  });
};
const deleteDrill = (id) => {
  return DELETE("/drills/" + id);
};
const setOrder = (drill_id, direction) => {
  return POST("/drills/set_order", {
    drill_id: drill_id,
    direction: direction,
  });
};
// Programs
const createProgram = (
  name,
  description,
  started,
  finished,
  status,
  usersCount
) => {
  return POST("/programs/", {
    name: name,
    description: description,
    started: started,
    finished: finished,
    status: status,
    group_users_count: usersCount,
  });
};
const getPrograms = (skip = 0, limit = 10) => {
  return GET("/programs/?skip=" + skip + "&limit=" + limit);
};
const getProgram = (id) => {
  return GET("/programs/" + id);
};
const updateProgram = (
  id,
  name,
  description,
  started,
  finished,
  status,
  usersCount
) => {
  return PUT("/programs/" + id, {
    name: name,
    description: description,
    started: started,
    finished: finished,
    status: status,
    group_users_count: usersCount,
  });
};
const deleteProgram = (id) => {
  return DELETE("/programs/" + id);
};
// Groups
const createGroup = (name, program, owner, created, starting_group) => {
  return POST("/groups/", {
    name: name,
    program_id: program,
    owner_id: owner,
    created: created,
    starting_group: starting_group
  });
};

const getGroups = (skip = 0, limit = 10) => {
  return GET("/groups/?skip=" + skip + "&limit=" + limit);
};
const getGroup = (id) => {
  return GET("/groups/" + id);
};

const getProgramGroups = (program_id) => {
  return GET("/programs/" + program_id + "/groups");
};

const generateGroupForProgram = (program_id) => {
  return POST("/programs/" + program_id + "/groups/generate");
};

const updateGroup = (id, name, program, owner, supporter_id, starting_group) => {
  return PUT("/groups/" + id, {
    name: name,
    program_id: program,
    owner_id: owner,
    supporter_id: supporter_id,
    starting_group: starting_group
  });
};
const updateGroup_from_object = (group) => {
  return PUT("/groups/" + group.id, group);
};
const deleteGroup = (id) => {
  return DELETE("/groups/" + id);
};
//Logs
const createLog = (
  user_id,
  drill_id,
  reflection,
  quality,
  resistance,
  finished,
  log_date
) => {
  return POST("/logs/", {
    user_id: user_id,
    drill_id: drill_id,
    reflection: reflection,
    quality: quality,
    resistance: resistance,
    finished: finished,
    log_date: log_date
  });
};

const getLogs = (skip = 0, limit = 10) => {
  return GET("/logs/?skip=" + skip + "&limit=" + limit);
};
const getLog = (id) => {
  return GET("/logs/" + id);
};
const updateLog = (
  id,
  user_id,
  drill_id,
  reflection,
  quality,
  resistance,
  finished,
  log_date
) => {
  return PUT("/logs/" + id, {
    user_id: user_id,
    drill_id: drill_id,
    reflection: reflection,
    quality: quality,
    resistance: resistance,
    finished: finished,
    log_date: log_date
  });
};
const deleteLog = (id) => {
  return DELETE("/logs/" + id);
};
const getUser2Programs = () => {
  return GET("/user_programs/");
};
const getUser2Groups = () => {
  return GET("/user_groups/");
};
const getWordings = () => {
  return GET("/wordings/");
};
const updateWording = (
  id,
  key,
  text
) => {
  return PUT("/wordings/" + id, {
    key: key,
    text:   text,
  });
};
export {
  GET,
  PUT,
  POST,
  DELETE,
  Login,
  getQualities,
  getResistances,
  creatUser2Group,
  creatUser2Programs,
  getPagination,
  getStats,
  getUserMe,
  createUser,
  getUsers,
  getUser,
  updateUser,
  deleteUser,
  createAvatar,
  createDrill,
  getDrills,
  getDrill,
  updateDrill,
  deleteDrill,
  setOrder,
  createProgram,
  getProgram,
  getPrograms,
  updateProgram,
  deleteProgram,
  getProgramStatuses,
  generateGroupForProgram,
  createGroup,
  getGroup,
  getGroups,
  updateGroup,
  updateGroup_from_object,
  deleteGroup,
  getProgramGroups,
  createLog,
  getLog,
  getLogs,
  updateLog,
  deleteLog,
  createUserGroup,
  getUser2Programs,
  getUser2Groups,
  getWordings,
  updateWording
};
