import React from "react";
import Header from "./Header";
import { useSelector } from "react-redux";
import NotAdmin from "../pages/notadmin";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

export default function Layout({ children }) {
  const { user } = useSelector((state) => state);

  if (user.is_superuser === false) return <NotAdmin />;
  return (
    <>
      {window.location.pathname !== "/login" && <Header />}

      <LocalizationProvider dateAdapter={AdapterMoment}>
        {children}
      </LocalizationProvider>
    </>
  );
}
