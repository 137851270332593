import React from "react";
import {
  deleteGroup,
  getProgramGroups,
  getUsers,
  updateGroup,
  createGroup,
} from "../services/api";
import { useAlert } from "react-alert";
import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import ConfirmDialog from "./Confirm";
import Button from "@mui/material/Button";
import UserGroupsManagement from "./pages/groups/userGroupsManagement";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Collapse from "@mui/material/Collapse";

const ProgramGroupsManagement = ({ programId }) => {
  const [groups, setGroups] = React.useState([]);
  const [owners, setOwners] = React.useState([]);
  const [confirmState, setConfirmState] = React.useState({ open: false });
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [manageGroups, setManageGroups] = React.useState(false);
  const [manageUsersInGroups, setManageUsersInGroups] = React.useState(true);

  const alert = useAlert();

  const editHandler = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const deleteHandler = (id) => {
    setTimeout(() => {
      deleteGroup(id)
        .then(() => {
          setGroups(groups.filter((row) => row.id !== id));
        })
        .catch((error) => alert.show(error));
    }, 500);
  };

  const deleteConfirm = (id) => {
    setConfirmState({
      open: true,
      id: id,
      text: "Do you really want to delete this group?",
    });
  };

  React.useEffect(() => {
    getUsers(0, 1000)
      .then(setOwners)
      .catch((error) => alert.show(error));

    refreshData();
  }, [alert]);

  const refreshData = (_) => {
    getProgramGroups(programId)
      .then(setGroups)
      .catch((error) => alert.show(error));
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = groups.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setGroups(groups.filter((row) => row.id !== id));
    }
  };

  function EditToolbar(props) {
    const { setRowModesModel } = props;

    const handleClick = () => {
      const id =
        Math.max(
          ...groups.map((group) => {
            return group.id;
          })
        ) + 1;
      setGroups((oldRows) => [
        ...oldRows,
        {
          id,
          program_id: programId,
          supporter_id: null,
          starting_group: false,
          owner_id: 0,
          created: new Date(),
          users: 0,
          isNew: true,
        },
      ]);
      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
      }));
    };

    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
          Add group
        </Button>
      </GridToolbarContainer>
    );
  }

  const processRowUpdate = (newRow) => {
    const editedGroup = groups.find((row) => row.id === newRow.id);
    if (editedGroup?.isNew) {
      createGroup(newRow.name, programId, newRow.owner_id, newRow.created, newRow.starting_group).then(
        (r) => {
          refreshData();
        }
      );
    } else {
      updateGroup(
        newRow.id,
        newRow.name,
        newRow.program_id,
        newRow.owner_id,
        newRow.supporter_id,
        newRow.starting_group
      ).then((r) => {
        refreshData();
      });
    }

    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      editable: true,
      flex: 1,
    },
    {
      field: "owner_id",
      headerName: "Owner",
      editable: true,
      type: "singleSelect",
      flex: 1,
      valueOptions: Object.values(owners),
      getOptionValue: (value) => value.id,
      getOptionLabel: (value) => value.full_name,
    },
    {
      field: "created",
      headerName: "Created",
      flex: 1,
      valueFormatter: (params) => new Date(params?.value).toLocaleDateString(),
    },
    {
      field: "users",
      headerName: "User Count",
      flex: 1,
      valueFormatter: (params) => params?.value?.length,
    },
    {
      field: "starting_group",
      headerName: "Starting group",
      flex: 1,
      type: "boolean",
      editable: true
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={() => editHandler(id)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => deleteConfirm(id)}
          />,
        ];
      },
    },
  ];

  return (
    <>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={manageGroups}
              onChange={(e) => setManageGroups(e.target.checked)}
              name="gilad"
            />
          }
          label="Manage groups"
        />
        <FormControlLabel
          control={
            <Switch
              checked={manageUsersInGroups}
              onChange={(e) => setManageUsersInGroups(e.target.checked)}
              name="gilad"
            />
          }
          label="Manage users in groups"
        />
      </div>
      <Collapse in={manageGroups}>
        <DataGrid
          editMode="row"
          rows={groups}
          columns={columns}
          rowModesModel={rowModesModel}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { refreshData, setRowModesModel },
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          autoHeight
        />
      </Collapse>
      <Collapse in={manageUsersInGroups}>
        <UserGroupsManagement
          groups={groups}
          setGroups={setGroups}
          refreshData={refreshData}
          program_id={programId}
        ></UserGroupsManagement>
      </Collapse>
      <ConfirmDialog
        state={confirmState}
        setConfirmState={setConfirmState}
        confirmCallback={deleteHandler}
      ></ConfirmDialog>
    </>
  );
};
export default ProgramGroupsManagement;
