import React, { useRef } from "react";
import { createDrill } from "../../services/api";
import { useAlert } from "react-alert";
import withToken from "../../hocs/withToken";
import Form from "../../components/pages/drills/form";
import { showError } from "../../utils";
const DrillsNew = () => {
  const [name, setName] = React.useState('');
  const [order, setOrder] = React.useState(0);
  const [description, setDescription] = React.useState('');
  const alert = useAlert();
  const [isActive, setIsActive] = React.useState(true);
  const [oldUsersOnly, setOldUsersOnly] = React.useState(false);

  const handleIsActiveChange = (event) => {
    setIsActive(event.target.checked);
  };

  const handleOldUsersOnly = (event) => {
    setOldUsersOnly(event.target.checked);
  };

  const submitHandle = (e) => {
    e.preventDefault();
    createDrill(
      name,
      order,
      description,
      isActive,
      oldUsersOnly
    ).then((response) => {
      response.name === name
        ? (window.location.href = "/drills")
        : showError(alert, response);
    });
  };
  return (
    <Form
      title={"Add new practice"}
      button={"Add"}
      submitHandle={submitHandle}
      name={name}
      handleNameChange={setName}
      order={order}
      handleOrderChange={setOrder}
      description={description}
      handleDescriptionChange={setDescription}
      isActive={isActive}
      handleIsActiveChange={handleIsActiveChange}
      oldUsersOnly={oldUsersOnly}
      handleOldUsersOnly={handleOldUsersOnly}
    />
  );
};

export default withToken(DrillsNew);
