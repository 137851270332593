import { GET, POST, PUT } from "./api";

const programs_url = "/programs";

const getProgramAssessements = (program_id) => {
  return GET(`${programs_url}/${program_id}/assessments/`);
};

const updateProgramAssessment = (program_id, assessment_id, available_at) => {
  return PUT(`${programs_url}/${program_id}/assessments/${assessment_id}`, {
    available_at: available_at,
  });
};

const copyTemplateToProgram = (program_id, assessment_id) => {
  return POST(
    `${programs_url}/${program_id}/assessments/${assessment_id}/copy`
  );
};

const saveAssessmentAsTemplate = (program_id, assessment_id) => {
  return POST(
    `${programs_url}/${program_id}/assessments/${assessment_id}/saveastemplate`
  );
};

export {
  getProgramAssessements,
  updateProgramAssessment,
  copyTemplateToProgram,
  saveAssessmentAsTemplate,
};
