import { GET, POST, PUT } from "./api";

const app_settings_url = "/app_settings";

const get = () => {
  return GET(app_settings_url);
};

const getByKey = (key) => {
  return GET(`${app_settings_url}/keys/${key}`);
};

const update = (
  id,
  key,
  value
) => {
  return PUT(app_settings_url + "/" + id, {
    key: key,
    value: value,
  });
};

export {
  get,
  getByKey,
  update
};
