import React from 'react';
import {createUser} from "../../services/api";
import {useAlert} from "react-alert";
import withToken from "../../hocs/withToken";
import Form from '../../components/pages/users/form'
import {showError} from "../../utils";

const UsersNew = () => {
    const refUsername = React.useRef()
    const refEmail = React.useRef()
    const refPassword = React.useRef()
    const [isSuperUser, setIsSuperUser] = React.useState(false)
    const [isSupporter, setIsSupporter] = React.useState(false)
    const [isActive, setIsActive] = React.useState(false)
    const [isOld, setIsOld] = React.useState(false)
    const [multiGroupsAvailable, setMultiGroupsAvailable] = React.useState(false)
    const [emailConfirmed, setEmailConfirmed] = React.useState(false)
    const alert = useAlert()

    const handleIsSuperUserChange = (event) => {
        setIsSuperUser(event.target.checked);
    };
    const handleIsSupporterChange = (event) => {
        setIsSupporter(event.target.checked);
    };
    const handleIsActiveChange = (event) => {
        setIsActive(event.target.checked);
    };
    const handleIsOldChange = (event) => {
        setIsOld(event.target.checked);
    };
    const handleEmailConfirmedChange = (event) => {
        setEmailConfirmed(event.target.checked);
    };
    const handleMultiGroupsAvailable = (event) => {
        setMultiGroupsAvailable(event.target.checked);
    };

    const submitHandle = (e) => {
        e.preventDefault()

        createUser(refUsername.current.value,
            refEmail.current.value,
            refPassword.current.value,
            isSuperUser,
            isSupporter,
            isActive,
            isOld,
            emailConfirmed,
            multiGroupsAvailable
        )
        .then((response) => {
            response.email === refEmail.current.value ? window.location.href = "/users" : showError(alert, response)
        })
    }
    return (<Form
        title={"Add new user"}
        button={"Add"}
        submitHandle={submitHandle}
        refUsername={refUsername}
        refEmail={refEmail}
        refPassword={refPassword}
        isSuperUser={isSuperUser}
        handleIsSuperUserChange={handleIsSuperUserChange}
        isSupporter={isSupporter}
        handleIsSupporter={handleIsSupporterChange}
        isActive={isActive}
        handleIsActiveChange={handleIsActiveChange}
        isOld={isOld}
        handleIsOldChange={handleIsOldChange}
        emailConfirmed={emailConfirmed}
        handleEmailConfirmedChange={handleEmailConfirmedChange}
        multiGroupsAvailable={multiGroupsAvailable}
        handleMultiGroupsAvailable={handleMultiGroupsAvailable}
    />)
}

export default withToken(UsersNew)