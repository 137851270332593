import React from "react";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import TinyMceEditor from "../../../components/TinyMceTextEditor";

export default function Form({
  title,
  button,
  submitHandle,
  name,
  handleNameChange,
  order,
  handleOrderChange,
  description,
  handleDescriptionChange,
  isActive,
  handleIsActiveChange,
  oldUsersOnly,
  handleOldUsersOnly,
}) {
  return (
    <>
      <h3>{title}</h3>
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <form onSubmit={submitHandle}>
              <div className="form-row mt-4">
                <div className="col-md-6 d-flex flex-column gap-1">
                  <TextField
                    value={name}
                    onChange={(event) => handleNameChange(event.target.value)}
                    id="name"
                    label="Name"
                    variant="outlined"
                  />
                  <TextField
                    value={order}
                    onChange={(event) => handleOrderChange(event.target.value)}
                    id="order"
                    label="Order"
                    variant="outlined"
                    type="number"
                  />
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleOldUsersOnly}
                          checked={oldUsersOnly}
                          id="oldUsersOnly"
                          variant="outlined"
                        />
                      }
                      label="Old users only"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleIsActiveChange}
                          checked={isActive}
                          id="isActive"
                          variant="outlined"
                        />
                      }
                      label="Is active"
                    />
                  </div>
                  <TinyMceEditor
                    value={description}
                    updateValue={handleDescriptionChange}
                  />
                </div>
                <div className="col-md-12 mt-3">
                  <Button type="submit" variant="contained">
                    {button}
                  </Button>
                  <Button
                    variant="standard"
                    onClick={() => (window.location.href = "/drills")}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
