import React from "react";
import withToken from "../../hocs/withToken";
import { useHistory } from "react-router-dom";
import TinyMceEditor from "../../components/TinyMceTextEditor";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";

import "../../assets/scss/components/wordings.scss";
import { getWordings, updateWording } from "../../services/api";

const Wordings = () => {
  const history = useHistory();
  const [wordings, setWordings] = React.useState([]);
  const [dailyLogPrompt, setDailyLogPrompt] = React.useState("");
  const [weeklyLogPrompt, setWeeklyLogPrompt] = React.useState("");
  const [goalPrompt, setGoalPrompt] = React.useState("");
  const [biggerWhyPrompt, setBiggerWhyPrompt] = React.useState("");
  const [alertText, setAlertText] = React.useState("");

    const save = () => {
        wordings.forEach(wording => {
            switch (wording.key){
                case "DailyLog":
                    wording.text = dailyLogPrompt;
                break;
                case "WeeklyLog":
                    wording.text = weeklyLogPrompt;
                break;
                case "Goal":
                    wording.text = goalPrompt;
                break;
                case "BiggerWhy":
                    wording.text = biggerWhyPrompt;
                break;
                default:
                    break;
            }
            updateWording(wording.id, wording.key, wording.text);
        });
        setAlertText("Data saved");
        setTimeout(() => {
          setAlertText('');
        }, 3000);
    };

  React.useEffect(() => {
    getWordings().then((w) => {
        setWordings(w);
        w.forEach(wording => {
            switch (wording.key){
                case "DailyLog":
                    setDailyLogPrompt(wording.text);
                break;
                case "WeeklyLog":
                    setWeeklyLogPrompt(wording.text);
                break;
                case "Goal":
                    setGoalPrompt(wording.text);
                break;
                case "BiggerWhy":
                    setBiggerWhyPrompt(wording.text);
                break;
                default:
                    break;
            }
        });
    })
  }, []);

  return (
    <>
      <h3 className="d-flex justify-content-between">
        <div>List of application wordings</div>
          <Button variant="contained" type="submit" onClick={save}>
            Save
          </Button>
      </h3>
      <div className="w-25 m-auto">
        {alertText && <Alert severity="success" variant="filled">{alertText}</Alert>}
      </div>
      <div className="wordings-editors m-3">
        <div>
          <div>Daily log prompt</div>
          <TinyMceEditor
            height={260}
            placeholder="Daily log prompt"
            value={dailyLogPrompt}
            updateValue={setDailyLogPrompt}
          />
        </div>
        <div>
          <div>Weekly log prompt</div>
          <TinyMceEditor
            height={260}
            placeholder="Weekly log prompt"
            value={weeklyLogPrompt}
            updateValue={setWeeklyLogPrompt}
          />
        </div>
        <div>
          <div>What is your Goal prompt</div>
          <TinyMceEditor
            height={260}
            placeholder="What is your Goal prompt"
            value={goalPrompt}
            updateValue={setGoalPrompt}
          />
        </div>
        <div>
          <div>What is your Bigger Why prompt</div>
          <TinyMceEditor
            height={260}
            placeholder="What is your Bigger Why prompt"
            value={biggerWhyPrompt}
            updateValue={setBiggerWhyPrompt}
          />
        </div>
      </div>
    </>
  );
};

export default withToken(Wordings);
