import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import withToken from "../../hocs/withToken";
import { useHistory, useParams } from "react-router-dom";
import {
  getProgramAssessements,
  updateProgramAssessment,
} from "../../services/prorgrams-api";
import {
  updateAssessment,
} from "../../services/assessments-api";
import { deleteProgramAssessment } from "../../services/program-assessment-api";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import AssessmentTemplatesModal from "../../components/assessments/assessmentTemplatesModal";
import { DataGrid, GridActionsCellItem, GridRowModes } from "@mui/x-data-grid";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ConfirmDialog from "../../components/Confirm";
import DeleteIcon from "@mui/icons-material/Delete";

const ProgramAssessments = () => {
  const alert = useAlert();
  const history = useHistory();
  const [programAssessments, setprogramAssessments] = useState([]);
  const [assessmentTemplatesModalOpen, setAssessmentTemplatesModalOpen] =
    useState(false);
  const [rowModesModel, setRowModesModel] = useState({});
  const [confirmState, setConfirmState] = useState({ open: false });
  const { id } = useParams();

  const addProgramAssessmentToTable = (program) => {
    setprogramAssessments((programAssessments) => [
      ...programAssessments,
      program,
    ]);
  };

  const goToDetailsPage = (assessment_id) => {
    history.push(`/programs/${id}/assessments/${assessment_id}`);
  };

  const editHandler = (id) => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const refreshData = () => {
    getProgramAssessements(id).then((assessments) => {
      setprogramAssessments(assessments);
    });
  };

  useEffect(() => {
    refreshData();
  }, []);

  const deleteConfirm = (params) => {
    setConfirmState({
      open: true,
      id: params.row.assessment.id,
      text:
        'Do you really want to delete "' + params.row.assessment.name + '" assessment?',
    });
  };

  const deleteHandler = (assessment_id) => {
    setTimeout(() => {
      deleteProgramAssessment(id, assessment_id)
        .then(() => {
          refreshData();
        })
        .catch((error) => alert.show(error));
    }, 500);
  };
  const processRowUpdate = (newRow) => {
    const editedProgramAssessments = programAssessments.find(
      (row) => row.id === newRow.id
    );
    if (editedProgramAssessments?.isNew) {
    } else {
      if (editedProgramAssessments.available_at != newRow.available_at) {
        updateProgramAssessment(
          editedProgramAssessments.program_id,
          editedProgramAssessments.id,
          newRow.available_at
        );
      } else if (
        editedProgramAssessments.assessment.name != newRow.assessment.name
      ) {
        updateAssessment(
          editedProgramAssessments.assessment_id,
          newRow.assessment.name,
          editedProgramAssessments.assessment.description
        );
      }
    }

    const updatedRow = { ...newRow, isNew: false };
    return updatedRow;
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "assessment_name",
      headerName: "Assessment",
      valueGetter: ({ row }) => row.assessment.name,
      valueSetter: (params) => {
        return { ...params.row, assessment: { name: params.value } };
      },
      flex: 1,
      editable: true,
    },
    {
      field: "available_at",
      headerName: "Available at program day:",
      flex: 1,
      type: "number",
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        } else {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={() => {
                goToDetailsPage(params.row.assessment_id);
              }}
            />,
            <GridActionsCellItem
              icon={<QuestionAnswerIcon />}
              label="View answers"
              onClick={() => {
                history.push(
                  `/programs/${id}/assessments/${params.row.assessment_id}/answers`
                );
              }}
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => deleteConfirm(params)}
            />,
          ];
        }
      },
    },
  ];

  return (
    <>
      <AssessmentTemplatesModal
        open={assessmentTemplatesModalOpen}
        setOpen={setAssessmentTemplatesModalOpen}
        addProgramAssessmentToTable={addProgramAssessmentToTable}
        programId={id}
      ></AssessmentTemplatesModal>
      <h3 className="d-flex justify-content-between">
        <div>List of program assessments</div>
        <div className="d-flex gap-1">
          <Button
            variant="contained"
            onClick={() => setAssessmentTemplatesModalOpen(true)}
          >
            Add from templates
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              goToDetailsPage(0);
            }}
          >
            Add
          </Button>
        </div>
      </h3>
      <DataGrid
        editMode="cell"
        rows={programAssessments}
        columns={columns}
        rowModesModel={rowModesModel}
        processRowUpdate={processRowUpdate}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        autoHeight
      />
      <ConfirmDialog
        state={confirmState}
        setConfirmState={setConfirmState}
        confirmCallback={deleteHandler}
      ></ConfirmDialog>
    </>
  );
};

export default withToken(ProgramAssessments);
